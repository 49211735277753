<template>
  <div class="home">
    <Spinner v-if="showSpinner"></Spinner>
    <b-alert :show="!showSpinner && !articleCollection.length" variant="success"
      >No Articles found! Continue searching your next preffered item</b-alert
    >
    <div v-if="isMobile">
      <b-button
        size="md"
        variant="outline-dark"
        class="mr-4 mt-4"
        @click="$router.push('/mypost')"
      >
        <b-icon-pencil-square class="mr-2"></b-icon-pencil-square>
        Write Someting
      </b-button>
      <b-button
        size="md"
        class="mt-4"
        variant="outline-dark"
        @click="$router.push('/trendingPost')"
        ><b-icon-graph-up font-scale="1" variant="danger" class="mr-2">
        </b-icon-graph-up
        >Trending Posts</b-button
      >
    </div>
    <b-row class="mx-0">
      <b-col cols="12" lg="9" class="px-0 px-md-2"
        ><div v-if="articleCollection.length">
          <div
            :class="{ 'blur-background': showSpinner }"
            v-for="post in articleCollection"
            :key="post._id"
          >
            <PostViewer :key="post._id" :article="post" read-more></PostViewer>
          </div></div
      ></b-col>
      <b-col cols="3" class="px-1"><TrendingPosts></TrendingPosts></b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { EventBus } from "../router/EventBus";
import {
  BAlert,
  BButton,
  BIconPencilSquare,
  BIconGraphUp,
} from "bootstrap-vue";

export default {
  name: "Home",

  components: {
    PostViewer: () => import(/* webpackPrefetch: true */ "./PostViewer.vue"),
    Spinner: () =>
      import(/* webpackPrefetch: true */ "../components/Spinner.vue"),
    TrendingPosts: () =>
      import(/* webpackPrefetch: true */ "../components/TrendingPosts.vue"),
    BAlert,
    BButton,
    BIconPencilSquare,
    BIconGraphUp,
  },

  data() {
    return {
      showSpinner: false,
      openGate: true,
      limit: 10,
      offset: 0,
      isMobile: false,
    };
  },

  computed: {
    ...mapGetters(["articleCollection"]),
  },

  created() {
    this.getAllArticles();
    window.addEventListener("scroll", this.handleScroll);
    this.isMobile = window.screen.width < 1024;

    EventBus.$on("resetOffset", () => {
      this.offset = 0;
      this.openGate = true;
    });
  },

  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },

  methods: {
    ...mapActions(["fetchAllArticles", "signOut"]),

    async handleScroll() {
      let bottomOfWindow =
        document.documentElement.scrollTop + window.innerHeight >=
        document.documentElement.offsetHeight - 1;

      if (this.openGate && bottomOfWindow) {
        this.showSpinner = true;
        this.openGate = false;

        this.offset = this.offset + 10;

        try {
          let res = await this.fetchAllArticles({
            limit: this.limit,
            offset: this.offset,
            shouldAdd: true,
          });

          if (res.length) this.openGate = true;
        } catch (e) {
          if (e.response.status === 401) {
            this.signOut();
          } else {
            console.error(e);
            this.openGate = true;
            this.offset = this.offset - 10;
          }
        } finally {
          this.showSpinner = false;
        }
      }
    },

    async getAllArticles() {
      this.showSpinner = true;
      try {
        await this.fetchAllArticles({
          limit: this.limit,
          offset: this.offset,
        });
      } catch (e) {
        if (e.response.status === 401) {
          this.signOut();
        }
        console.error(e);
      } finally {
        this.showSpinner = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.blur-background {
  opacity: 0.25;
}
</style>
